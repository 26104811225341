import { boot } from 'quasar/wrappers'

import { LoggerConfig } from 'src/modules/LoggerService'

import { Client } from 'src/store/Client'

import { System } from "src/store/System"

import "./TypeBoxSetup" // side-effects only

/**
 * In devmode, return hooks for use with playwright
 * In production, return undefined
 */
const $iltest = (() => {
  if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
    const v = {
      LoggerConfig,
      System,
      Client,
    } as const;

    return v;
  }

  return undefined;
})()

// the type of $iltest is the return type of $iltest, omitting undefined
export type $iltest = Exclude<typeof $iltest, undefined>;

export default boot(({ app, store }) => {
  const $publicPath = process.env.MODE === 'capacitor' ? '' : '/app'
  Client.directCommit_setPublicPath($publicPath)

  System.directCommit_setIsMobile(process.env.isMobile === true)

  if (process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) {
    (window as any).$iltest = $iltest;
    // Run the "playwright init hook", if it exists.
    // This can be installed via `page.addInitScript` in a test.
    (window as any).$iltest_onInit?.($iltest);

    // try to print the chain of data-test elements from root->`elem` in a way that can be pasted into playwright tests
    // example output is like `page.getByTestId("foo").getByTestId("bar").getByTestId("baz")`
    (window as any).$ilDataTestPath = (elem: HTMLElement) => {
      const dataTestChain : [string, HTMLElement][] = []
      let workingElem : Node | null = elem

      while (workingElem) {
        if (workingElem instanceof HTMLElement) {
          const attr = workingElem instanceof HTMLElement
            ? workingElem.getAttribute("data-test")
            : null

          if (attr !== null) {
            dataTestChain.push([attr, workingElem])
          }
        }

        workingElem = workingElem.parentNode
      }

      {
        const m = [...dataTestChain].reverse().map(([testid]) => `getByTestId('${testid}')`).join(".")
        if (m) {
          console.log("page." + m)
        }
        else {
          console.log(null)
        }
      }
    }
  }
})
